import { useStyletron } from '@tigergraph/app-ui-lib/Theme';

export default function Error404() {
  const [css] = useStyletron();
  return (
    <div>
      <p
        className={css({
          paddingTop: '300px',
          paddingBottom: '60px',
          fontFamily: 'Urbanist',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '32px',
          lineHeight: '40px',
          textAlign: 'center',
          textTransform: 'capitalize',
          color: '#294560',
        })}
      >
        Page not found
      </p>
      <span className={css({ width: '100%', textAlign: 'center', display: 'table' })}>
        <svg width="163" height="160" viewBox="0 0 163 160" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g className={css({ mixBlendMode: 'multiply' })}>
            <path
              d="M71.9475 78.0212C64.7058 78.0212 58.8354 83.929 58.8354 91.2167C58.8354 98.5043 64.7058 104.412 71.9475 104.412C79.1891 104.412 85.0596 98.5043 85.0596 91.2167C85.0596 83.929 79.1891 78.0212 71.9475 78.0212Z"
              stroke="#AAB5BF"
              stroke-width="6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <g className={css({ mixBlendMode: 'multiply' })}>
            <path
              d="M104.5 35C98.701 35 94 39.701 94 45.5C94 51.299 98.701 56 104.5 56C110.299 56 115 51.299 115 45.5C115 39.701 110.299 35 104.5 35Z"
              stroke="#AAB5BF"
              stroke-width="6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <g className={css({ mixBlendMode: 'multiply' })}>
            <path
              d="M16.0503 43.9998C10.2537 43.9998 5.55463 48.7288 5.55463 54.5622C5.55463 60.3956 10.2537 65.1245 16.0503 65.1245C21.8468 65.1245 26.5459 60.3956 26.5459 54.5622C26.5459 48.7288 21.8468 43.9998 16.0503 43.9998Z"
              stroke="#AAB5BF"
              stroke-width="6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <g className={css({ mixBlendMode: 'multiply' })}>
            <path
              d="M13.9985 129.875C8.20193 129.875 3.50288 134.604 3.50288 140.438C3.50288 146.271 8.20193 151 13.9985 151C19.7951 151 24.4941 146.271 24.4941 140.438C24.4941 134.604 19.7951 129.875 13.9985 129.875Z"
              stroke="#AAB5BF"
              stroke-width="6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <g className={css({ mixBlendMode: 'multiply' })}>
            <path
              d="M32.5827 82.2103C27.64 82.2103 23.6332 86.2426 23.6332 91.2167C23.6332 96.1908 27.64 100.223 32.5827 100.223C37.5254 100.223 41.5322 96.1908 41.5322 91.2167C41.5322 86.2426 37.5254 82.2103 32.5827 82.2103Z"
              stroke="#AAB5BF"
              stroke-width="6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <g className={css({ mixBlendMode: 'multiply' })}>
            <path
              d="M103.435 115.932C97.638 115.932 92.9389 120.661 92.9389 126.494C92.9389 132.328 97.638 137.057 103.435 137.057C109.231 137.057 113.93 132.328 113.93 126.494C113.93 120.661 109.231 115.932 103.435 115.932Z"
              stroke="#AAB5BF"
              stroke-width="6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <g className={css({ mixBlendMode: 'multiply' })}>
            <path
              d="M98.1714 117.369L81.5211 100.224"
              stroke="#AAB5BF"
              stroke-width="6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <g className={css({ mixBlendMode: 'multiply' })}>
            <path
              d="M61.5112 79.2178L24.7022 60.5167"
              stroke="#AAB5BF"
              stroke-width="6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <g className={css({ mixBlendMode: 'multiply' })}>
            <path d="M80 77L78 79" stroke="#AAB5BF" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
          </g>
          <g className={css({ mixBlendMode: 'multiply' })}>
            <path
              d="M62.7012 104.233L25.238 130.235"
              stroke="#AAB5BF"
              stroke-width="6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <g className={css({ mixBlendMode: 'multiply' })}>
            <path
              d="M58.5984 91.7248L41.294 91.9941"
              stroke="#AAB5BF"
              stroke-width="6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <g className={css({ mixBlendMode: 'multiply' })}>
            <path d="M99 55L91 64" stroke="#AAB5BF" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
          </g>
          <g className={css({ mixBlendMode: 'multiply' })}>
            <path
              d="M129.867 61.231L156.454 87.8174C159.179 90.5424 159.179 94.9793 156.454 97.7043C153.729 100.429 149.292 100.429 146.567 97.7043L119.596 70.7336"
              stroke="#F48120"
              stroke-width="8"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <g className={css({ mixBlendMode: 'multiply' })}>
            <path
              d="M102.687 75.3788C121.017 75.3788 135.876 60.5194 135.876 42.1894C135.876 23.8594 121.017 9 102.687 9C84.357 9 69.4976 23.8594 69.4976 42.1894C69.4976 60.5194 84.357 75.3788 102.687 75.3788Z"
              stroke="#F48120"
              stroke-width="8"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      </span>
    </div>
  );
}
