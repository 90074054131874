export enum LicenseStatus {
  Valid = 'Valid',
  Invalid = 'Invalid',
  Expired = 'Expired',
  NoLicense = 'No License',
}

export interface ToolsLicense {
  GST: {
    Enable: boolean;
    ZoomChartsLicense: string;
  };
  Insights: {
    Enable: boolean;
  };
  GSQLShell: {
    Enable: boolean;
  };
  GraphQL: {
    Enable: boolean;
  };
  Status: LicenseStatus;
}
