import React, { useEffect } from 'react';
import { useStyletron, styled } from '@tigergraph/app-ui-lib/Theme';

import { ToolHead } from '../../components/toolHead';

import studio from '../../assets/studio.svg';
import admin from '../../assets/admin.svg';
import insights from '../../assets/insights.svg';
import gsqlshell from '../../assets/gsqlshell.svg';
import graphql from '../../assets/graphql.svg';
import { useLicense } from '../../license/license';
import axios from 'axios';
import { useQuery } from 'react-query';

const StyledAppList = styled('div', {
  maxWidth: '1200px',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingBottom: '20px',
  display: 'grid',
  gap: '48px',
  gridTemplateColumns: 'repeat(auto-fill, 520px)',
  aligntems: 'center',
  justifyItems: 'center',
  justifyContent: 'center',
  '@media screen and (max-width: 720px)': {
    width: '100%',
  },
});

function AppBox({ disabled, children, ...props }: { disabled?: boolean } & React.ComponentProps<'a'>) {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        cursor: disabled ? 'not-allowed' : 'pointer',
      })}
    >
      <a
        {...props}
        className={css({
          background: disabled ? theme.colors.gray300 : '#FFFFFF',
          borderRadius: '10px',
          display: 'flex',
          width: '520px',
          height: '150px',
          pointerEvents: disabled ? 'none' : 'auto',
          border: '1px solid #D4DADF',
          textDecoration: 'none',
          paddingLeft: '36px',
          paddingRight: '36px',
          alignItems: 'center',
          boxSizing: 'border-box',
          ':hover': {
            boxShadow: '-2px -2px 30px rgba(25, 118, 210, 0.05), 2px 2px 30px rgba(25, 118, 210, 0.05)',
          },
          position: 'relative',
          '@media screen and (max-width: 720px)': {
            width: '80%',
          },
          overflow: 'hidden',
        })}
      >
        {children}
      </a>
    </div>
  );
}

const StyledAppIMG = styled('div', {
  marginRight: '16px',
  width: '184px',
  height: '96px',
  display: 'flex',
  alignItems: 'center',
});

const StyledBoxinfo = styled('p', {
  fontFamily: 'Urbanist',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '16px',
  color: '#767676',
});

let userInfo = { isSuperUser: false, name: '' };
let version = {
  tigergraph_version: '',
  is_graphstudio_enabled: false,
  is_adminportal_enabled: false,
  is_insights_enabled: false,
  is_graphql_enabled: false,
  is_gsqlshell_enabled: false,
};

export default function AllApplication() {
  const [css] = useStyletron();
  const { license } = useLicense();

  useEffect(() => {
    const fetchVersion = async () => {
      const response = await axios.get(`/api/version`).then((res) => res.data);
      if (!response.error) {
        version.tigergraph_version = response.results.tigergraph_version;
        version.is_graphstudio_enabled =
          response.results.is_graphstudio_enabled === undefined || response.results.is_graphstudio_enabled;
        version.is_adminportal_enabled =
          response.results.is_adminportal_enabled === undefined || response.results.is_adminportal_enabled;
        version.is_insights_enabled =
          response.results.is_insights_enabled === undefined || response.results.is_insights_enabled;
        version.is_graphql_enabled =
          response.results.is_graphql_enabled === undefined || response.results.is_graphql_enabled;
        version.is_gsqlshell_enabled =
          response.results.is_gsqlshell_enabled === undefined || response.results.is_gsqlshell_enabled;
      } else {
        console.warn(`failed to get tigergraph version: ${response.message}`);
      }
    };
    fetchVersion();
  }, []);

  useQuery(
    'authQuery',
    async () => {
      const response = await axios.get(`/api/gsql-server/gsql/simpleauth`);
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (data.response && data.response.status === 401) {
          window.location.href = `${window.location.origin}/#/login`;
          return;
        }
        userInfo = data.results;
      },
    }
  );

  useEffect(() => {
    const { pathname } = window.location;
    const isMlwbRoute = pathname.startsWith('/mlwb');
    // The front-end of mlwb uses the front-end resources of the instance gui
    if (process.env.REACT_APP_ENV === 'cloud' && isMlwbRoute) {
      window.location.href = `/#/login?returnURL=${encodeURIComponent('/mlwb')}`;
      return;
    }
    if (process.env.REACT_APP_ENV === 'cloud') {
      window.location.href = 'https://tgcloud.io';
    }
  }, []);

  if (process.env.REACT_APP_ENV === 'cloud') {
    return null;
  }

  return (
    <>
      <div
        className={css({
          width: '100%',
        })}
      >
        <ToolHead
          appname="TigerGraph Suite"
          appicon="/suittitle.svg"
          innerapp="home"
          user={userInfo.name}
          isSuperUser={userInfo.isSuperUser}
        />
      </div>
      <p
        className={css({
          paddingTop: '60px',
          paddingBottom: '60px',
          fontFamily: 'Urbanist',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '32px',
          lineHeight: '40px',
          textAlign: 'center',
          textTransform: 'capitalize',
          color: '#294560',
        })}
      >
        What do you want to work on today?
      </p>
      {(version.tigergraph_version === '' || userInfo.name === '') && (
        <div className="gradientLoading">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <svg
            className="loadingIcon"
            width="57"
            height="55"
            viewBox="0 0 57 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.54872 20.6845C0.786368 19.9172 -0.58206 18.5398 0.270232 17.6819C6.66269 13.3925 5.38419 9.53202 12.2028 5.67157C19.0214 1.81111 21.5478 2.69975 21.5784 2.66896C24.9877 -0.762605 33.511 0.0953075 33.511 0.0953075C33.511 2.66896 31.3802 5.67157 31.3802 5.67157C43.938 12.5346 40.3296 28.4055 40.3296 28.4055C46.2959 20.2556 43.7389 11.6768 43.7389 11.6768C57.3761 22.4003 39.9034 40.8449 39.9034 40.8449C54.8192 32.266 54.393 21.5424 54.393 21.5424C66.7517 41.2738 31.8063 53.1412 19.8738 55C36.4941 33.9818 30.1017 20.2556 26.2662 17.253C30.1017 24.974 27.1185 30.0355 27.1185 29.6924C24.9877 27.5477 22.4307 26.2608 18.1691 26.6898C13.9075 27.1187 10.4982 31.4081 9.21966 32.695C8.79352 33.1239 5.8119 30.6131 4.95803 28.4055C3.79664 25.4029 4.29078 25.273 4.53187 24.545C4.95805 23.2582 3.2534 22.4003 1.54876 20.6845L1.54872 20.6845Z"
              fill="#EE9240"
            />
          </svg>
        </div>
      )}
      {userInfo.name !== '' && version.tigergraph_version !== '' && (
        <StyledAppList>
          <AppBox href="/studio" disabled={license.disableGraphStudio || (version && !version.is_graphstudio_enabled)}>
            <StyledAppIMG>
              <img
                className={css({
                  width: '184px',
                  height: '96px',
                })}
                src={studio}
                alt=""
              />
            </StyledAppIMG>
            <StyledBoxinfo>Design, develop, map and load your graph database.</StyledBoxinfo>
          </AppBox>

          <AppBox href="/insights" disabled={license.disableInsights || (version && !version.is_insights_enabled)}>
            <StyledAppIMG>
              <img
                className={css({
                  width: '184px',
                  height: '96px',
                })}
                src={insights}
                alt=""
              />
            </StyledAppIMG>
            <StyledBoxinfo>Build graph powered applications in minutes.</StyledBoxinfo>
          </AppBox>

          <AppBox href="/gsql" disabled={license.disableGSQLShell || (version && !version.is_gsqlshell_enabled)}>
            <StyledAppIMG>
              <img
                className={css({
                  width: '184px',
                  height: '96px',
                })}
                src={gsqlshell}
                alt=""
              />
            </StyledAppIMG>
            <StyledBoxinfo>Run your GSQL and visualize it.</StyledBoxinfo>
          </AppBox>
          <AppBox
            href="/admin"
            disabled={userInfo && !userInfo.isSuperUser && version && !version.is_adminportal_enabled}
          >
            <StyledAppIMG>
              <img
                className={css({
                  width: '184px',
                  height: '96px',
                })}
                src={admin}
                alt=""
              />
            </StyledAppIMG>
            <StyledBoxinfo>Manage your TigerGraph system.</StyledBoxinfo>
          </AppBox>
          <AppBox href="/graphql" disabled={license.disableGraphQL || (version && !version.is_graphql_enabled)}>
            <StyledAppIMG>
              <img
                className={css({
                  width: '184px',
                  height: '96px',
                })}
                src={graphql}
                alt=""
              />
            </StyledAppIMG>
            <StyledBoxinfo>Query and update your graph data at ease via GraphQL API.</StyledBoxinfo>
          </AppBox>
        </StyledAppList>
      )}
    </>
  );
}
