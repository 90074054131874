import { useCallback, useEffect, createRef } from 'react';
import { Input } from '@tigergraph/app-ui-lib/input';
import { FormControl } from 'baseui/form-control';
import { Button } from '@tigergraph/app-ui-lib/button';
import { useStyletron, styled, withStyle } from 'baseui';
import { useQuery, useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { useState } from 'react';
import 'particles.js';
import { Spinner } from 'baseui/spinner';
import { getHashSearchParam } from '../../utils/utils';
import fotlogin from '../../assets/fotlogin.svg';
import errorsvg from '../../assets/error.svg';
import { compare } from 'compare-versions';

declare global {
  interface Window {
    particlesJS(tag_id: string, params: Record<string, unknown>): void;
  }
}
type LoginError = {
  error: boolean;
  message: string;
  results: Object;
};

const particleOptions: Record<string, unknown> = {
  particles: {
    number: {
      value: 180,
      density: {
        enable: true,
        value_area: 800,
      },
    },
    color: {
      value: '#ffffff',
    },
    shape: {
      type: 'circle',
      stroke: {
        width: 0,
        color: '#000000',
      },
      polygon: {
        nb_sides: 5,
      },
      image: {
        src: 'img/github.svg',
        width: 100,
        height: 100,
      },
    },
    opacity: {
      value: 0.5,
      random: false,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false,
      },
    },
    size: {
      value: 3,
      random: true,
      anim: {
        enable: false,
        speed: 1,
        size_min: 0.1,
        sync: false,
      },
    },
    line_linked: {
      enable: true,
      distance: 150,
      color: '#ffffff',
      opacity: 0.4,
      width: 1,
    },
    move: {
      enable: true,
      speed: 1,
      direction: 'none',
      random: false,
      straight: false,
      out_mode: 'out',
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200,
      },
    },
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onhover: {
        enable: true,
        mode: 'grab',
      },
      onclick: {
        enable: true,
        mode: 'push',
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 140,
        line_linked: {
          opacity: 1,
        },
      },
      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 8,
        speed: 1,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  retina_detect: true,
};

type Response = {
  error: boolean;
  message: string;
  results: {
    isGlobalDesigner: boolean;
    isSuperUser: boolean;
    name: string;
    roles: {
      [key: string]: string[];
    };
    securityRecommendations: string[];
  };
};

type OIDCResponse = {
  error: boolean;
  message: string;
  results: {
    OIDCRequest: string;
    ssoBinding: string;
  };
};

type VersionResponse = {
  error: boolean;
  message: string;
  results: {
    tigergraph_version: string;
  };
};

const StyledContainer = styled('div', {
  background: 'linear-gradient(180deg, #E8842C 0%, #FFFAF6 100%)',
  position: 'absolute',
  top: '0px',
  left: '0px',
  bottom: '0px',
  right: '0px',
});

const StyledLoadingContainer = styled('div', {
  width: '100%',
  height: 'auto',
  display: 'flex',
  marginTop: '10px',
  justifyContent: 'center',
});

const StyledFormContainer = styled('div', {
  height: 'auto',
  width: '480px',
  background: '#FFFFFF',
  borderRadius: '10px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: '-207px',
  marginLeft: '-240px',
  paddingTop: '56px',
  paddingLeft: '60px',
  paddingRight: '60px',
  paddingBottom: '56px',
  boxSizing: 'border-box',
  '@media screen and (max-width: 720px)': {
    width: '90%',
    left: '5%',
    marginLeft: '0px',
    top: '5%',
    marginTop: '0px',
  },
});

const CenterContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
});

const ExtraLargeSpinner = withStyle(Spinner, {
  width: '16px',
  height: '16px',
  borderLeftWidth: '2px',
  borderRightWidth: '2px',
  borderTopWidth: '2px',
  borderBottomWidth: '2px',
  borderTopColor: '#ee9844',
  borderBottomColor: 'transparent',
  borderLeftColor: 'transparent',
  borderRightColor: 'transparent',
  marginRight: '10px',
});

export default function Login() {
  const formRef = createRef<HTMLFormElement>();

  const getSSOAuthnRequestURL = '/api/gsql-server/gsql/saml/authnrequest';
  const getOIDCAuthnRequestURL = '/api/gsql-server/gsql/oidc/authnrequest';
  const getAuthnRequestURL = '/api/auth/login';
  const logOutURL = '/api/auth/logout';
  const versionURL = '/api/version';

  const [username, setUserName] = useState(process.env.REACT_APP_TG_USERNAME);
  const [password, setPassword] = useState(getHashSearchParam('loggedOut') ? '' : process.env.REACT_APP_TG_PASSWORD);
  const [loading, setLoading] = useState(false);
  const autoLogin = process.env.REACT_APP_ENV === 'cloud' && !getHashSearchParam('loggedOut');
  const [oidcLoading, setOidcLoading] = useState(process.env.REACT_APP_ENV === 'cloud' && autoLogin);
  const [loginerror, setLoginerror] = useState(false);
  const [errormsg, setErrormsg] = useState('User authentication failed.');

  const [css] = useStyletron();

  const versionResponse = useQuery('version', () => axios.get<VersionResponse>(versionURL));
  const version = versionResponse.data?.data?.results?.tigergraph_version;
  const supportOIDC = version && compare(version, '3.10.0', '>=');

  useQuery('LOGOUT', () => axios.post(logOutURL), {
    onSuccess: () => {
      ssoResponse.mutate();
      oidcResponse.mutate();
    },
  });

  const ssoResponse = useMutation('SSO', () =>
    axios.get(getSSOAuthnRequestURL).then((response) => response && response.data)
  );

  const oidcResponse = useMutation(
    'OIDC',
    () => axios.get<OIDCResponse>(getOIDCAuthnRequestURL).then((response) => response && response.data),
    {
      onSuccess: (response) => {
        if (autoLogin) {
          onOIDCLogin(response);
        }
      },
      onError: () => {
        setOidcLoading(false);
      },
    }
  );

  const loginAPI = async () => {
    try {
      setLoading(true);
      const response = await axios.post<Response>(`${getAuthnRequestURL}`, {
        username,
        password,
      });
      if (response.data) {
        window.localStorage.setItem('TigerGraphUIUsername', JSON.stringify(response.data.results.name));
        const { securityRecommendations } = response.data.results;
        if (securityRecommendations) {
          window.localStorage.setItem('TigerGraphSecurityRecommendations', JSON.stringify(securityRecommendations));
        }
        let returnURL = getReturnUrl();
        window.location.replace(`${window.location.origin}${returnURL}`);
        setLoading(false);
      }
    } catch (error) {
      const err = error as AxiosError;
      const errdata = err.response?.data as LoginError;
      const statusCode = err.response?.status;
      if (errdata && statusCode !== 401) {
        setErrormsg(errdata.message);
      }
      setLoading(false);
      setLoginerror(true);
    }
  };

  function handlFormChange(type: string) {
    return (e) => {
      switch (type) {
        case 'username':
          setUserName(e.target.value);
          break;
        case 'password':
          setPassword(e.target.value);
          break;
        default:
          break;
      }
    };
  }

  async function ssoLogin() {
    formRef.current?.submit();
  }

  function onOIDCLogin(response?: OIDCResponse) {
    let oidcResponseRes = response || oidcResponse.data;
    let returnState = getHashSearchParam('returnURL') || '/apps';
    if (returnState === '/apps') {
      returnState = '/#' + returnState;
    }
    if (returnState.indexOf('/mlwb') !== -1) {
      returnState = `${sessionStorage.getItem('BASEURL')}/mlwb/`;
    } else {
      returnState = window.location.origin + returnState;
    }
    const state = btoa(returnState);
    if (oidcResponseRes) {
      const ssoOIDCRequest = oidcResponseRes.results.OIDCRequest + `&state=${state}`;
      window.open(ssoOIDCRequest, '_self');
    }
  }

  /**
   * Retrieve error and return url.
   * Auto login if it is not logged out by user.
   *
   * @private
   * @memberof LoginComponent
   */
  const retrieveParamsFromRouteAndAutoLogin = useCallback(() => {
    const errMsg = getHashSearchParam('loginFailure');
    if (errMsg) {
      console.log(errMsg);
    }
    if (!getHashSearchParam('loggedOut') && process.env.REACT_APP_ENV === 'testdrive') {
      loginAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process.env.REACT_APP_ENV === 'cloud']);

  function onInputKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter' && username !== '' && password !== '') {
      event.preventDefault();
      event.stopPropagation();
      loginAPI();
    }
  }

  function getReturnUrl() {
    const routeArr = ['/studio/', '/admin/', '/gsql/', '/insights/', '/graphql/', '/workbench', '/mlwb'];
    let returnURL = decodeURIComponent(getHashSearchParam('returnURL') || '');
    const redirectToApp = routeArr.some((route) => {
      return returnURL.indexOf(route) === 0;
    });
    if (redirectToApp) {
      return returnURL;
    }
    return '/#/apps';
  }

  useEffect(() => {
    retrieveParamsFromRouteAndAutoLogin();
  }, [retrieveParamsFromRouteAndAutoLogin]);

  useEffect(() => {
    if (document.querySelector('#particles-js')) {
      window.particlesJS('particles-js', particleOptions);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {oidcLoading ? (
        <StyledLoadingContainer>
          <Spinner />
        </StyledLoadingContainer>
      ) : (
        <StyledContainer id="particles-js">
          <StyledFormContainer
            onClick={() => {
              setLoginerror(false);
            }}
          >
            <div
              className={css({
                paddingBottom: '24px',
                borderBottom: '1px solid rgba(212, 218, 223, 0.4)',
              })}
            >
              <CenterContainer>
                <img
                  className={css({
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  })}
                  src={fotlogin}
                  alt=""
                />
              </CenterContainer>
            </div>
            {process.env.REACT_APP_ENV === 'cloud' ? (
              <Button
                onClick={() => {
                  onOIDCLogin();
                }}
                overrides={{
                  BaseButton: {
                    style: () => ({
                      backgroundColor: '#FB9A44',
                      width: '360px',
                      height: '40px',
                      color: '#222222',
                      borderRadius: '5px',
                      marginTop: '10px',
                    }),
                  },
                }}
              >
                Login with TigerGraph Cloud
              </Button>
            ) : (
              <>
                {ssoResponse.data ? (
                  <>
                    <Button
                      onClick={ssoLogin}
                      overrides={{
                        BaseButton: {
                          style: () => ({
                            backgroundColor: '#FFFFFF',
                            borderLeft: '2px solid #FB9A44',
                            borderRight: '2px solid #FB9A44',
                            borderTop: '2px solid #FB9A44',
                            borderBottom: '2px solid #FB9A44',
                            width: '360px',
                            height: '40px',
                            color: '#222222',
                            borderRadius: '5px',
                            marginTop: '24px',
                            ':hover': {
                              backgroundColor: '#FFFFFF',
                            },
                          }),
                        },
                      }}
                    >
                      Login With SSO
                    </Button>
                    <div
                      className={css({
                        borderBottom: '1px solid rgba(212, 218, 223, 0.4)',
                        marginTop: '32px',
                        marginBottom: '32px',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                      })}
                    >
                      <div
                        className={css({
                          fontFamily: 'Roboto',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '14px',
                          lineHeight: '16px',
                          color: '#767676',
                          backgroundColor: '#FFFFFF',
                          marginBottom: '-8px',
                        })}
                      >
                        or Login with username
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div style={{ height: '72px' }}>
                  <FormControl
                    label="Username"
                    caption=""
                    overrides={{
                      Label: {
                        style: () => ({
                          fontFamily: 'Roboto',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '14px',
                          lineHeight: '16px',
                          color: '#222222',
                        }),
                      },
                    }}
                  >
                    <Input
                      value={username}
                      onChange={handlFormChange('username')}
                      overrides={{
                        Input: {
                          style: () => ({
                            '&::-webkit-input-placeholder': {
                              color: '#767676',
                            },
                            '&::-moz-placeholder': {
                              color: '#767676',
                            },
                            '&::ms-input-placeholder': {
                              color: '#767676',
                            },
                          }),
                        },
                        Root: {
                          style: () => {
                            return {
                              borderLeftColor: '#AAB5BF',
                              borderRightColor: '#AAB5BF',
                              borderTopColor: '#AAB5BF',
                              borderBottomColor: '#AAB5BF',
                            };
                          },
                        },
                      }}
                      onKeyDown={onInputKeyDown}
                    />
                  </FormControl>
                </div>
                <div style={{ height: '83px' }}>
                  <FormControl
                    label="Password"
                    caption=""
                    overrides={{
                      Label: {
                        style: () => ({
                          fontFamily: 'Roboto',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '14px',
                          lineHeight: '16px',
                          color: '#222222',
                        }),
                      },
                    }}
                  >
                    <Input
                      type="password"
                      value={password}
                      autoComplete="off"
                      onChange={handlFormChange('password')}
                      overrides={{
                        MaskToggleHideIcon: () => (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 4C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19C17 19 21.27 15.89 23 11.5C21.27 7.11 17 4 12 4ZM12 16.5C9.24 16.5 7 14.26 7 11.5C7 8.74 9.24 6.5 12 6.5C14.76 6.5 17 8.74 17 11.5C17 14.26 14.76 16.5 12 16.5ZM12 8.5C10.34 8.5 9 9.84 9 11.5C9 13.16 10.34 14.5 12 14.5C13.66 14.5 15 13.16 15 11.5C15 9.84 13.66 8.5 12 8.5Z"
                              fill="#3F5870"
                            />
                          </svg>
                        ),
                        MaskToggleShowIcon: () => (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 7C14.76 7 17 9.24 17 12C17 12.65 16.87 13.26 16.64 13.83L19.56 16.75C21.07 15.49 22.26 13.86 22.99 12C21.26 7.61 16.99 4.5 11.99 4.5C10.59 4.5 9.25 4.75 8.01 5.2L10.17 7.36C10.74 7.13 11.35 7 12 7ZM2 4.27L4.28 6.55L4.74 7.01C3.08 8.3 1.78 10.02 1 12C2.73 16.39 7 19.5 12 19.5C13.55 19.5 15.03 19.2 16.38 18.66L16.8 19.08L19.73 22L21 20.73L3.27 3L2 4.27ZM7.53 9.8L9.08 11.35C9.03 11.56 9 11.78 9 12C9 13.66 10.34 15 12 15C12.22 15 12.44 14.97 12.65 14.92L14.2 16.47C13.53 16.8 12.79 17 12 17C9.24 17 7 14.76 7 12C7 11.21 7.2 10.47 7.53 9.8V9.8ZM11.84 9.02L14.99 12.17L15.01 12.01C15.01 10.35 13.67 9.01 12.01 9.01L11.84 9.02Z"
                              fill="#3F5870"
                            />
                          </svg>
                        ),
                        Input: {
                          style: () => ({
                            '&::-webkit-input-placeholder': {
                              color: '#767676',
                            },
                            '&::-moz-placeholder': {
                              color: '#767676',
                            },
                            '&::ms-input-placeholder': {
                              color: '#767676',
                            },
                          }),
                        },
                        Root: {
                          style: () => {
                            return {
                              borderLeftColor: '#AAB5BF',
                              borderRightColor: '#AAB5BF',
                              borderTopColor: '#AAB5BF',
                              borderBottomColor: '#AAB5BF',
                            };
                          },
                        },
                      }}
                      onKeyDown={onInputKeyDown}
                    />
                  </FormControl>
                </div>
                {loginerror && (
                  <div
                    className={css({
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      fontFamily: 'Roboto',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '12px',
                      color: '#B93535',
                      height: '16px',
                      marginTop: '-2px',
                      marginBottom: '10px',
                    })}
                  >
                    <img className={css({ marginRight: '2px' })} src={errorsvg} alt="" />
                    {errormsg}
                  </div>
                )}
                <div
                  className={css({
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  })}
                >
                  <CenterContainer>
                    <Button
                      onClick={loginAPI}
                      type="submit"
                      disabled={!!(!username || !password) || loading}
                      overrides={{
                        BaseButton: {
                          style: () => ({
                            backgroundColor: '#FB9A44',
                            width: '360px',
                            '@media screen and (max-width: 720px)': {
                              width: '100%',
                            },
                            height: '40px',
                            color: '#222222',
                            borderRadius: '5px',
                            marginTop: '10px',
                          }),
                        },
                      }}
                    >
                      {loading ? (
                        <>
                          <ExtraLargeSpinner />
                          Logging in
                        </>
                      ) : (
                        'Login'
                      )}
                    </Button>
                    {process.env.REACT_APP_ENV !== 'cloud' && supportOIDC && oidcResponse.data && (
                      <Button
                        onClick={() => {
                          onOIDCLogin();
                        }}
                        overrides={{
                          BaseButton: {
                            style: () => ({
                              backgroundColor: '#FB9A44',
                              width: '360px',
                              height: '40px',
                              color: '#222222',
                              borderRadius: '5px',
                              marginTop: '10px',
                            }),
                          },
                        }}
                      >
                        Login with OIDC
                      </Button>
                    )}
                  </CenterContainer>
                  {ssoResponse.data ? (
                    <>
                      <form
                        className={css({
                          width: '0px',
                          height: '0px',
                          position: 'absolute',
                          zIndex: '-2',
                          visibility: 'hidden',
                        })}
                        id="loginform"
                        action={ssoResponse.data.results.ssoUrl}
                        ref={formRef}
                        method={ssoResponse.data.results.ssoSAMLBinding || 'POST'}
                      >
                        <input readOnly name="SAMLRequest" value={ssoResponse.data.results.SAMLRequest} />
                        <input readOnly name="SigAlg" value={ssoResponse.data.results.SigAlg} />
                        <input readOnly name="Signature" value={ssoResponse.data.results.Signature} />
                        <input readOnly name="RelayState" value={btoa(getReturnUrl())} />
                      </form>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            )}
          </StyledFormContainer>
        </StyledContainer>
      )}
    </>
  );
}
